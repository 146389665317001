import { fork } from 'redux-saga/effects';

import { authSaga } from '../actions/auth';
import { logsSaga } from '../actions/logs';
import { usersSaga } from '../actions/users';
import { organizationsSaga } from '../actions/organizations';
import { materialCertificationsSaga } from '../actions/materialCertifications';
import { collectionsSaga } from '../actions/collections';
import { certificationsSaga } from '../actions/certifications';
import { ordersSaga } from '../actions/orders';
import { providerCertificationsSaga } from '../actions/providerCertifications';
import { revenuesSaga } from '../actions/revenue';
import { majInfoProductSaga } from '../actions/majInfoProduct';
import { factoriesSaga } from '../actions/factories';
import { auditsSaga } from '../actions/audits';
import { typeOfAuditsSaga } from '../actions/typeOfAudits';
import { subContractorsSaga } from '../actions/SubContractors';
import { conformityDocumentsSaga } from '../actions/conformityDocuments';
import { materialsSaga } from '../actions/materials';
import { submitSaga } from '../actions/submit';
import { apiInfosSaga } from '../actions/apiInfos';
import { remoteFileSaga } from '../actions/remoteFiles';

export default function* mainSaga() {
    yield fork(authSaga);
    yield fork(apiInfosSaga);
    yield fork(logsSaga);
    yield fork(usersSaga);
    yield fork(organizationsSaga);
    yield fork(materialCertificationsSaga);
    yield fork(collectionsSaga);
    yield fork(certificationsSaga);
    yield fork(ordersSaga);
    yield fork(providerCertificationsSaga);
    yield fork(revenuesSaga);
    yield fork(majInfoProductSaga);
    yield fork(factoriesSaga);
    yield fork(auditsSaga);
    yield fork(typeOfAuditsSaga);
    yield fork(subContractorsSaga);
    yield fork(conformityDocumentsSaga);
    yield fork(materialsSaga);
    yield fork(submitSaga);
    yield fork(remoteFileSaga);
}
