import { RemoteFile } from './apiTypes';
import api from './_client';

export interface RemoteFileDownloadPayload {
    id: RemoteFile['id'];
    filename: string;
}

export const download = async (payload: RemoteFileDownloadPayload) => {
    return await api.get(`/remoteFiles/download/${payload.id}`, {
        headers: {
            'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
    });
};
