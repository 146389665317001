import { combineReducers } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';

import { RemoteFile } from '../api/apiTypes';
import { requestReducer } from '../helpers';
import { MainReducerState, RequestState } from '../reducers';

import dayjs from 'dayjs';
import { downloadBlobFile } from '../../helpers';
import { download as downloadApiCall, RemoteFileDownloadPayload } from '../api/remoteFiles';
import { DataAction } from '../helpers/EzeeAction';

export interface RemoteFileState {
    download: RequestState<string>;
}

const initialState: RemoteFileState = {
    download: {
        data: undefined,
        loading: false,
    },
};
export const download = new EzeeAsyncAction<RemoteFileState['download'], RemoteFileDownloadPayload, RemoteFile>(
    'remoteFiles/download',
    initialState.download,
    requestReducer<RemoteFileState['download'], RemoteFile>(initialState.download)
);

export const remoteFileReducer = combineReducers<RemoteFileState>({
    download: download.reducer,
});

function* downloadSaga(action: DataAction<RemoteFileDownloadPayload>) {
    try {
        const response = yield call(downloadApiCall, action.payload);

        const fileName = action.payload.filename || `${dayjs().format('YYYY-MM-DD_HH[h]mm')}-document.pdf`;
        downloadBlobFile(fileName, response, 'application/pdf');

        return yield put(download.success(''));
    } catch (error) {
        return yield put(download.failure(error));
    }
}

export function* remoteFileSaga() {
    yield takeLatest(download.type.trigger, downloadSaga);
}

export const getRemoteFileDownloadState = (state: MainReducerState) => state.remoteFile;
