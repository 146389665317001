import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Drawer, DrawerProps, Form, FormProps, Input, Modal, Row, Typography } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FC, KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import '../../assets/styles/ConfirmAddSubcontractorModal.less';

import { useSelector } from 'react-redux';
import ButtonIcon from '../../components/ButtonIcon';
import CountrySelect from '../../components/CountrySelect';
import SelectSubcontractorActivity from '../../components/form/SelectSubcontractorActivity';
import GenericFileList from '../../components/GenericFileList';
import LabelWithTooltipIcon from '../../components/LabelWithTooltipIcon';
import PhoneInput from '../../components/PhoneInput';
import RequiredFields from '../../components/RequiredFields';
import TitleAlt from '../../components/TitleAlt';
import UploadFile from '../../components/UploadFile';
import { classNames, downloadBlobFile } from '../../helpers';
import { errorMessage, successMessage } from '../../helpers/message';
import { useActions, usePrevious } from '../../hooks';
import formMessages from '../../i18n/formMessages';
import genericMessages from '../../i18n/genericMessages';
import { getUser } from '../../store/actions/auth';
import {
    create as createSubContractor,
    details as subContractorDetails,
    downloadAttachment as downloadSubcontractorAttachment,
    getDownloadAttachmentState,
    getSubContractorsActivitiesState,
    getSubContractorsCreateState,
    getSubContractorsDetailsState,
    getSubContractorsUpdateState,
    update as updateSubContractor,
} from '../../store/actions/SubContractors';
import { ValidationStatus } from '../../store/api/apiTypes';
import { EntityToDownload } from '../../store/api/SubContractors';
interface SubContractorFormDrawerProps extends DrawerProps {
    subContractorId?: string;
}

const SubContractorFormDrawer: FC<SubContractorFormDrawerProps> = ({ subContractorId, onClose, ...props }) => {
    const { formatMessage } = useIntl();
    const [fileToUpload, setFileToUpload] = useState<RcFile | undefined>();
    const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
    const [attachment, setAttachment] = useState<RcFile | undefined>();
    const [attachmentToDelete, setAttachmentToDelete] = useState<string | undefined>();
    const [form] = Form.useForm();
    const [
        create,
        update,
        loadSubContractor,
        subContractorDetailsReset,
        downloadAttachment,
        downloadAttachmentReset,
    ] = useActions([
        createSubContractor.trigger,
        updateSubContractor.trigger,
        subContractorDetails.trigger,
        subContractorDetails.reset,
        downloadSubcontractorAttachment.trigger,
        downloadSubcontractorAttachment.reset,
    ]);

    const subContractorDetailState = useSelector(getSubContractorsDetailsState);
    const subContractorUpdateState = useSelector(getSubContractorsUpdateState);
    const subContractorCreateState = useSelector(getSubContractorsCreateState);
    const downloadSubcontractorAttachmentState = useSelector(getDownloadAttachmentState);
    const activitiesState = useSelector(getSubContractorsActivitiesState);
    const user = useSelector(getUser);
    const previous = usePrevious({
        subContractorDetailState,
        subContractorUpdateState,
        subContractorCreateState,
        activitiesState,
        downloadSubcontractorAttachmentState,
    });
    const requiredRule = { required: true, message: formatMessage(formMessages.requiredField) };
    const subcontractorMustBeValidated = user?.organization?.subcontractorValidation;
    const resetState = () => {
        setAttachmentToDelete(undefined);
        setShowFileUpload(false);
        setAttachment(undefined);
        setFileToUpload(undefined);
    };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        if (subContractorId) {
            update({
                file: fileToUpload,
                ...values,
                attachmentToDelete,
                id: subContractorId,
                provider: user?.organization?.id,
            });
        } else {
            if (subcontractorMustBeValidated) {
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
                Modal.confirm({
                    className: 'confirm_add_subcontractor_modal',
                    centered: true,
                    closable: true,
                    icon: null,
                    title: (
                        <>
                            <Typography.Title style={{ padding: '16px 24px', marginBottom: '0' }}>
                                {formatMessage({
                                    id: 'subcontractor_form_drawer.modal.add.title',
                                    defaultMessage: 'Ajout du sous-traitant',
                                    description: 'add subconstractor modal title',
                                })}
                            </Typography.Title>
                            <Divider style={{ margin: '0' }} />
                        </>
                    ),
                    content: (
                        <>
                            <Typography.Paragraph style={{ padding: '24px', marginBottom: '0' }}>
                                {formatMessage({
                                    id: 'subcontractor_form_drawer.modal.add.content',
                                    defaultMessage:
                                        'Nous vous confirmons l’ajout du sous-traitant : il devra être validé par Dior.',
                                    description: 'add subconstractor modal content',
                                })}
                            </Typography.Paragraph>
                            <Divider style={{ margin: '0' }} />
                        </>
                    ),
                    onOk() {
                        create({
                            file: fileToUpload,
                            ...values,
                            validationStatus: ValidationStatus.pending,
                        });
                    },
                    okText: formatMessage({
                        id: 'subcontractor_form_drawer.modal.validate',
                        defaultMessage: 'valider',
                        description: 'validate',
                    }),
                    cancelText: formatMessage({
                        id: 'subcontractor_form_drawer.modal.cancel',
                        defaultMessage: 'annuler',
                        description: 'cancel',
                    }),
                    cancelButtonProps: { type: 'primary', shape: 'round', ghost: true, style: { width: '127px' } },
                    okButtonProps: { type: 'primary', shape: 'round', style: { width: '127px' } },
                    width: 520,
                });
            } else {
                create({ ...values, validationStatus: ValidationStatus.autoValidated });
            }
        }
    };
    const onBeforeClose = () => {
        if (form.isFieldsTouched()) {
            Modal.confirm({
                title: formatMessage({
                    id: 'subcontractor_form_drawer.modal.title',
                    defaultMessage: "Êtes-vous sûr de vouloir quitter la création / l'édition du sous traitant  ?",
                    description: 'close drawer subconstractor',
                }),
                icon: <ExclamationCircleOutlined />,
                content: formatMessage({
                    id: 'subcontractor_form_drawer.modal.content',
                    defaultMessage: 'Les informations saisies seront perdues',
                    description: 'close drawer subconstractor contente',
                }),
                onOk() {
                    resetState();
                    onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
                },
                okText: formatMessage({
                    id: 'subcontractor_form_drawer.modal.ok',
                    defaultMessage: 'oui',
                    description: 'ok',
                }),
                cancelText: formatMessage({
                    id: 'subcontractor_form_drawer.modal.cancel',
                    defaultMessage: 'annuler',
                    description: 'cancel',
                }),
            });
        } else {
            resetState();
            onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
        }
    };

    const onAfterVisibleChange: DrawerProps['afterVisibleChange'] = (visible) => {
        if (!visible) {
            form.resetFields();
        }
    };
    useEffect(() => {
        if (subContractorId) {
            loadSubContractor({ id: subContractorId });
        }
    }, [loadSubContractor, subContractorId]);

    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.subContractorDetailState.loading && !subContractorDetailState.loading) {
            if (subContractorDetailState.error || !subContractorDetailState.data) {
                errorMessage({
                    content: formatMessage({
                        id: 'subcontractor_form_drawer.error_message.detail',
                        defaultMessage: 'Une erreur est survenue pendant la récupération du sous traitant',
                        description: 'Factory Error Message',
                    }),
                });
            } else {
                form.setFieldsValue(subContractorDetailState.data);
            }
        }
    }, [previous?.subContractorDetailState.loading, subContractorDetailState, form, formatMessage]);

    useEffect(() => {
        // si loading ÉTAIT true, et que MAINTENANT il est false, cela veut dire que la requête est finie
        if (previous?.subContractorUpdateState.loading && !subContractorUpdateState.loading) {
            if (subContractorUpdateState.error || !subContractorUpdateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'subcontractor_form_drawer.success_message.edit',
                        defaultMessage: 'Sous-traitant édité avec succès',
                        description: 'Factory Success Message',
                    }),
                });
                resetState();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
        if (previous?.subContractorCreateState.loading && !subContractorCreateState.loading) {
            if (subContractorCreateState.error || !subContractorCreateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'subcontractor_form_drawer.success_message.create',
                        defaultMessage: 'Sous-traitant crée avec succès',
                        description: 'Factory Success Message',
                    }),
                });
                resetState();
                onClose?.({} as KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement | HTMLButtonElement>);
            }
        }
    }, [previous, subContractorUpdateState, subContractorCreateState, formatMessage, onClose]);
    useEffect(() => {
        if (downloadSubcontractorAttachmentState.success && subContractorDetailState.data?.attachment) {
            downloadBlobFile(
                subContractorDetailState.data?.attachment.originalName,
                downloadSubcontractorAttachmentState.data
            );
            downloadAttachmentReset();
            resetState();
        }
    }, [downloadSubcontractorAttachmentState, subContractorDetailState, downloadAttachmentReset, setFileToUpload]);
    const showUploadDragger =
        !subContractorId || (!!subContractorId && (!subContractorDetailState.data?.attachment || showFileUpload));
    const handleFileChange = (file: RcFile | undefined) => {
        setFileToUpload(file);
        setAttachmentToDelete(undefined);
    };
    const handleDownloadAttachmentFromAPI = () => {
        downloadAttachment({ id: subContractorId, entity: EntityToDownload.attachment });
        resetState();
        subContractorDetailsReset();
    };

    return (
        <Drawer
            width={500}
            title={
                subContractorId
                    ? formatMessage({
                          id: 'subcontractor_form_drawer.title.edit',
                          defaultMessage: 'Modifier le sous-traitant',
                          description: 'Drawer title',
                      })
                    : formatMessage({
                          id: 'subcontractor_form_drawer.title.add',
                          defaultMessage: 'Ajouter un sous-traitant',
                          description: 'Drawer title',
                      })
            }
            onClose={onBeforeClose}
            afterVisibleChange={onAfterVisibleChange}
            {...props}
        >
            {subcontractorMustBeValidated && (
                <Typography.Paragraph strong style={{ fontSize: '16px', marginBottom: '32px' }}>
                    <FormattedMessage
                        id="subcontractor_form_drawer.description"
                        defaultMessage="Avant toute collaboration, Dior procédera à la validation du sous-traitant."
                        description="Drawer description"
                    />
                </Typography.Paragraph>
            )}

            <Form
                form={form}
                onFinish={onFormValidSubmit}
                layout="vertical"
                requiredMark={false}
                initialValues={{ contacts: [{}] }}
                scrollToFirstError
            >
                <Form.Item
                    label={formatMessage({
                        id: 'subcontractor_form_drawer.form.item.name',
                        defaultMessage: "Nom de l'entreprise",
                        description: 'field label',
                    })}
                    rules={[requiredRule]}
                    name="companyName"
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'subcontractor_form_drawer.form.item.input.name',
                            defaultMessage: 'Saisir le nom',
                            description: 'field placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item label={formatMessage(genericMessages.dunsNumber)} name="duns">
                    <Input
                        placeholder={formatMessage({
                            id: 'subcontractor_form_drawer.form.item.contact.input.phone',
                            defaultMessage: 'Saisir un numéro',
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.label.address',
                                defaultMessage: 'Adresse de fabrication',
                                description: 'input label',
                            })}
                            tooltip={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.tooltip.address',
                                defaultMessage: "Localisation de l'usine de votre sous-traitant",
                                description: 'input help tooltip',
                            })}
                        />
                    }
                    rules={[requiredRule]}
                    name={['address', 'street']}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'subcontractor_form_drawer.form.item.input.address',
                            defaultMessage: 'Saisir une adresse',
                            description: 'field placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Row gutter={24}>
                    <Col xs={9}>
                        <Form.Item
                            name={['address', 'zipCode']}
                            label={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.label.zipCode',
                                defaultMessage: 'Code postal',
                                description: 'input label',
                            })}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage({
                                    id: 'subcontractor_form_drawer.form.item.tooltip.zipCode',
                                    defaultMessage: 'Saisir un code postal',
                                    description: 'input placeholder',
                                })}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={15}>
                        <Form.Item
                            name={['address', 'city']}
                            label={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.label.city',
                                defaultMessage: 'Ville',
                                description: 'input label',
                            })}
                            rules={[requiredRule]}
                        >
                            <Input
                                placeholder={formatMessage({
                                    id: 'subcontractor_form_drawer.form.item.input.city',
                                    defaultMessage: 'Saisir une ville',
                                    description: 'input placeholder',
                                })}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name={['address', 'country']}
                    label={formatMessage({
                        id: 'subcontractor_form_drawer.form.item.label.country',
                        defaultMessage: 'Pays',
                        description: 'input label',
                    })}
                    rules={[requiredRule]}
                >
                    <CountrySelect
                        placeholder={formatMessage({
                            id: 'subcontractor_form_drawer.form.item.select.country',
                            defaultMessage: 'Sélectionner un pays',
                            description: 'input placeholder',
                        })}
                        size="large"
                    />
                </Form.Item>
                <Form.Item
                    name="activities"
                    label={
                        <LabelWithTooltipIcon
                            label={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.label.activity',
                                defaultMessage: 'Activité',
                                description: 'input label',
                            })}
                            tooltip={formatMessage({
                                id: 'subcontractor_form_drawer.form.item.tooltip.activity',
                                defaultMessage: 'Activité principale du sous-traitant',
                                description: 'input help tooltip',
                            })}
                        />
                    }
                    rules={[requiredRule]}
                >
                    <SelectSubcontractorActivity
                        placeholder={formatMessage({
                            id: 'subcontractor_form_drawer.form.item.select.activity',
                            defaultMessage: "Sélectionner l'activité",
                            description: 'input placeholder',
                        })}
                        size="large"
                        mode="multiple"
                    />
                </Form.Item>
                <Divider />
                <TitleAlt level={4}>
                    <FormattedMessage
                        id="subcontractor_form_drawer.form.item.title.contact"
                        defaultMessage="Contact(s) au sein du sous-traitant"
                        description="form section title"
                    />
                </TitleAlt>
                <Form.List name="contacts">
                    {(fields, { add, remove }, { errors }) => (
                        <Card
                            className={classNames('form-list-wrapper', fields.length > 1 && 'has-more-than-one-field')}
                            bordered={false}
                            size="small"
                            style={{ marginBottom: '1.5rem' }}
                        >
                            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                <Card
                                    className={classNames(
                                        'form-list-wrapper',
                                        fields.length > 1 && 'has-more-than-one-field'
                                    )}
                                    bordered={false}
                                    size="small"
                                    key={key}
                                    style={{ marginBottom: '1.5rem' }}
                                >
                                    {fields.length > 1 && (
                                        <div className="flex flex-between" style={{ marginBottom: '1rem' }}>
                                            <Typography.Paragraph style={{ margin: 0 }}>
                                                <p>
                                                    {formatMessage(
                                                        {
                                                            id: 'subcontractor_form_drawer.form.item.contact.number',
                                                            defaultMessage: 'Contact n°{index}',
                                                            description: 'label',
                                                        },
                                                        { index: index + 1 }
                                                    )}
                                                </p>
                                            </Typography.Paragraph>
                                            <ButtonIcon onClick={() => remove(name)} icon={<DeleteOutlined />} />
                                        </div>
                                    )}
                                    <Row gutter={24}>
                                        <Col xs={12}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'lastName']}
                                                fieldKey={[fieldKey, 'lastName']}
                                                label={formatMessage({
                                                    id: 'subcontractor_form_drawer.form.item.contact.label.lastname',
                                                    defaultMessage: 'Nom',
                                                    description: 'input label',
                                                })}
                                                rules={[requiredRule]}
                                            >
                                                <Input
                                                    placeholder={formatMessage({
                                                        id:
                                                            'subcontractor_form_drawer.form.item.contact.input.lastname',
                                                        defaultMessage: 'Saisir un nom',
                                                        description: 'input placeholder',
                                                    })}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'firstName']}
                                                fieldKey={[fieldKey, 'firstName']}
                                                label={formatMessage({
                                                    id: 'subcontractor_form_drawer.form.item.contact.label.firstname',
                                                    defaultMessage: 'Prénom',
                                                    description: 'input label',
                                                })}
                                                rules={[requiredRule]}
                                            >
                                                <Input
                                                    placeholder={formatMessage({
                                                        id:
                                                            'subcontractor_form_drawer.form.item.contact.tooltip.firstname',
                                                        defaultMessage: 'Saisir un prénom',
                                                        description: 'input placeholder',
                                                    })}
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        name={[name, 'email']}
                                        fieldKey={[fieldKey, 'email']}
                                        label={formatMessage({
                                            id: 'subcontractor_form_drawer.form.item.contact.label.email',
                                            defaultMessage: 'Adresse e-mail',
                                            description: 'input label',
                                        })}
                                        rules={[
                                            requiredRule,
                                            { type: 'email', message: formatMessage(formMessages.invalidEmail) },
                                        ]}
                                    >
                                        <Input
                                            placeholder={formatMessage({
                                                id: 'subcontractor_form_drawer.form.item.contact.tooltip.email',
                                                defaultMessage: 'Saisir une adresse e-mail',
                                                description: 'input placeholder',
                                            })}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'phone']}
                                        fieldKey={[fieldKey, 'phone']}
                                        label={formatMessage({
                                            id: 'subcontractor_form_drawer.form.item.contact.label.phone',
                                            defaultMessage: 'Téléphone fixe',
                                            description: 'input label',
                                        })}
                                    >
                                        <PhoneInput
                                            placeholder={formatMessage({
                                                id: 'subcontractor_form_drawer.form.item.contact.input.phone',
                                                defaultMessage: 'Saisir un numéro',
                                                description: 'input placeholder',
                                            })}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, 'job']}
                                        fieldKey={[fieldKey, 'job']}
                                        label={formatMessage({
                                            id: 'subcontractor_form_drawer.form.item.contact.lable.job',
                                            defaultMessage: 'Poste / Titre',
                                            description: 'input label',
                                        })}
                                        rules={[requiredRule]}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <Input
                                            placeholder={formatMessage({
                                                id: 'subcontractor_form_drawer.form.item.contact.input.job',
                                                defaultMessage: 'Saisir le poste / titre',
                                                description: 'input placeholder',
                                            })}
                                            size="large"
                                        />
                                    </Form.Item>
                                </Card>
                            ))}
                            <Form.Item className="form-list-add-more">
                                <Button type="default" shape="round" size="small" onClick={() => add()} block>
                                    <FormattedMessage
                                        id="subcontractor_form_drawer.form.item.contact.button.add"
                                        defaultMessage="Ajouter un contact supplémentaire"
                                        description="dynamic form item add-more button"
                                    />
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </Card>
                    )}
                </Form.List>
                <Divider />

                <Form.Item
                    label={formatMessage({
                        id: 'subcontractor_form_drawer.form.item.label.subcontractor_attachment',
                        defaultMessage: 'Pièce jointe',
                    })}
                >
                    {showUploadDragger ? (
                        <UploadFile
                            fileType="document"
                            maxSize={5}
                            fileTypeText={formatMessage(formMessages.uploadFileFormatAndSize)}
                            onChange={handleFileChange}
                            file={attachment}
                            setFile={setAttachment}
                        />
                    ) : subContractorDetailState.data?.attachment ? (
                        <GenericFileList
                            file={subContractorDetailState.data?.attachment}
                            onFileDownload={handleDownloadAttachmentFromAPI}
                            onFileRemove={() => {
                                setAttachmentToDelete('attachment');
                                setShowFileUpload(true);
                            }}
                            className="bg-lightish-grey"
                        />
                    ) : null}
                </Form.Item>

                <Button
                    loading={subContractorCreateState.loading || subContractorUpdateState.loading}
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    size="large"
                    block
                >
                    {subContractorId
                        ? formatMessage({
                              id: 'subcontractor_form_drawer.form.item.button.edit',
                              defaultMessage: 'Enregistrer les modifications',
                              description: 'form submit button',
                          })
                        : formatMessage({
                              id: 'subcontractor_form_drawer.form.item.button.add',
                              defaultMessage: 'Ajouter le sous-traitant',
                              description: 'form submit button',
                          })}
                </Button>
                <p style={{ margin: '1.5rem 0 0', textAlign: 'center' }}>
                    <RequiredFields />
                </p>
            </Form>
        </Drawer>
    );
};

export default SubContractorFormDrawer;
