import { combineReducers } from 'redux';
import { takeLatest } from 'redux-saga/effects';

// Helpers
import { simpleAsyncSaga } from '../helpers/EzeeSaga';
import { EzeeAsyncAction } from '../helpers/EzeeAsyncAction';
import { MainReducerState, RequestState } from '../reducers';
import { requestReducer } from '../helpers';

import { submit as submitApiCall, SubmitInformationPayload } from '../api/submit';

// States
export interface SubmitState {
    submit: RequestState;
}

const initialState: SubmitState = {
    submit: {
        loading: false,
    },
};

export const submit = new EzeeAsyncAction<SubmitState['submit'], SubmitInformationPayload>(
    'MajInfoProducts/submit',
    initialState.submit,
    requestReducer<SubmitState['submit']>(initialState.submit)
);

// Reducer
export const submitReducer = combineReducers({
    submit: submit.reducer,
});

// Saga
export function* submitSaga() {
    yield takeLatest(submit.type.trigger, simpleAsyncSaga(submitApiCall, submit));
}

// Store helpers
export const getSubmitState = (state: MainReducerState) => state.submit.submit;
