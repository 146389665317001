import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { classNames } from '../helpers';
import { useActions } from '../hooks';
import { download as downloadAction } from '../store/actions/remoteFiles';
import { RemoteFile } from '../store/api/apiTypes';
import { RemoteFileDownloadPayload } from '../store/api/remoteFiles';
interface GenericFileListProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    noNeedFileDownload?: boolean;
    noNeedFileRemove?: boolean;
    file: RemoteFile;
    onFileRemove?: () => void;
    onFileDownload?: () => void;
    downloadOnClick?: boolean;
}
const GenericFileList: FC<GenericFileListProps> = ({
    noNeedFileDownload,
    noNeedFileRemove,
    file,
    onFileRemove,
    onFileDownload,
    downloadOnClick,
    ...props
}) => {
    const [download] = useActions([downloadAction.trigger]);
    const handleFileDownload = (p: RemoteFileDownloadPayload) => {
        if (onFileDownload) {
            onFileDownload();
        } else {
            download(p);
        }
    };
    const handleClick = downloadOnClick
        ? () => handleFileDownload({ filename: file.originalName ?? '', id: file.id })
        : undefined;
    return (
        <div {...props} className={classNames('uploaded-file', props.className)}>
            <div
                role={downloadOnClick ? 'button' : undefined}
                className={classNames(
                    'filename',
                    downloadOnClick ? classNames('underline', 'cursor-pointer') : undefined
                )}
                onClick={handleClick}
                onKeyDown={handleClick}
            >
                {file.originalName}
            </div>
            <div className="actions">
                {!noNeedFileDownload && (
                    <Button
                        type="text"
                        icon={<DownloadOutlined />}
                        onClick={() => handleFileDownload({ filename: file.originalName ?? '', id: file.id })}
                    />
                )}
                {!noNeedFileRemove && <Button type="text" icon={<DeleteOutlined />} onClick={onFileRemove} />}
            </div>
        </div>
    );
};

export default GenericFileList;
