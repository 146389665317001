import { CheckCircleOutlined, CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Descriptions, Divider, message, Space, Switch, SwitchProps, Tooltip, Typography } from 'antd';
import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useModal } from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import ButtonIcon from '../../components/ButtonIcon';
import ConfirmationModal from '../../components/CustomModal';
import DeletePopConfirm from '../../components/DeletePopConfirm';
import EditOutlinedWithToolTip from '../../components/EditOutlinedWithToolTip';
import { IconHourglass } from '../../components/icons';
import Translate from '../../components/Translate';
import { downloadBlobFile, getFullName } from '../../helpers';
import { errorMessage, successMessage } from '../../helpers/message';
import { useActions, usePrevious } from '../../hooks';
import genericMessages from '../../i18n/genericMessages';
import { getUser } from '../../store/actions/auth';
import { getOrganizationsUpdateState, update as updateOrganization } from '../../store/actions/organizations';
import {
    getSubContractorsListState,
    getSubContractorsRemoveState,
    getDownloadAttachmentState,
    list as subContractorsList,
    remove as removeSubContractors,
    downloadAttachment as downloadSubcontractorAttachment,
} from '../../store/actions/SubContractors';
import { SubContractor, SupportedLanguage, Translation, ValidationStatus } from '../../store/api/apiTypes';
import SubContractorFormDrawer from './SubContractorFormDrawer';
import GenericFileList from '../../components/GenericFileList';
import { EntityToDownload } from '../../store/api/SubContractors';

type LangImport = { [key in SupportedLanguage]: any };

const availableLangs = ['es', 'de', 'ru', 'fr', 'jp', 'cn', 'pt', 'it', 'ir', 'ar', 'tr', 'id', 'en'];
const intlData = availableLangs.reduce<LangImport | Record<string, unknown>>(
    (acc, lang) => ({
        ...acc,
        [lang]: async () => await import(`react-phone-input-2/lang/${lang}.json`),
    }),
    {}
);
interface SubContractorListProps {
    setSubContractorRevoked: React.Dispatch<React.SetStateAction<boolean>>;
    subContractorRevoked: boolean;
}
const subcontractorConfirmationModalId = 'subcontractorConfirmationModalId';
const SubContractorList: FC<SubContractorListProps> = ({ subContractorRevoked, setSubContractorRevoked }) => {
    const { formatMessage, locale } = useIntl();
    const user = useSelector(getUser);
    const organizationUpdateState = useSelector(getOrganizationsUpdateState);
    const [isFormDrawerVisible, setIsFormDrawerVisible] = useState(false);
    const [downloadedFileName, setDownloadedFileName] = useState<string | undefined>();
    const [selectedSubContractor, setSelectedSubContractor] = useState<SubContractor['id'] | undefined>();
    const [loadSubContractors, remove, downloadAttachment, downloadAttachmentReset] = useActions([
        subContractorsList.trigger,
        removeSubContractors.trigger,
        downloadSubcontractorAttachment.trigger,
        downloadSubcontractorAttachment.reset,
    ]);
    const [updateProvider] = useActions([updateOrganization.trigger]);
    const subContractorListState = useSelector(getSubContractorsListState);
    const subContractorRemoveState = useSelector(getSubContractorsRemoveState);
    const downloadSubcontractorAttachmentState = useSelector(getDownloadAttachmentState);
    const [localization, setLocalization] = useState<{ [key: string]: string } | undefined>();
    const subContractorsDisabled = useMemo(
        () =>
            user?.organization?.subContractorsEnabled !== undefined
                ? !user?.organization?.subContractorsEnabled
                : false,
        [user?.organization]
    );
    const previous = usePrevious({ subContractorRemoveState, organizationUpdateState });
    const onClickAdd = () => {
        setIsFormDrawerVisible(true);
    };
    const onClickEdit = (subContractor: SubContractor) => {
        setSelectedSubContractor(subContractor.id);
        setIsFormDrawerVisible(true);
    };
    const onClickDelete = (subContractorId: SubContractor['id']) => {
        remove({ id: subContractorId });
    };
    const onCloseFormDrawer = () => {
        setSelectedSubContractor(undefined);
        loadSubContractors();
        setIsFormDrawerVisible(false);
    };
    const confirmModal = useModal(subcontractorConfirmationModalId);

    const onChangeShowConfirmationModal: SwitchProps['onChange'] = (checked: boolean) => {
        setSubContractorRevoked(checked);
        if (checked && !!subContractorListState.data?.items.length) {
            confirmModal.show();
        } else {
            confirmModal.hide();
        }
    };
    const confirmSubcontractorRevoke = () => {
        updateProvider({
            disableSubContractors: true,
            id: user?.organization?.id,
        });
        confirmModal.hide();
    };
    const handleModalClose = () => {
        setSubContractorRevoked(subContractorsDisabled);
        confirmModal.hide();
    };
    useEffect(() => {
        loadSubContractors();
    }, [loadSubContractors]);

    useEffect(() => {
        if (previous?.organizationUpdateState?.loading && !organizationUpdateState.loading) {
            if (organizationUpdateState.error || !organizationUpdateState.data) {
                errorMessage({
                    content: formatMessage(genericMessages.defaultError),
                });
            } else {
                successMessage({
                    content: formatMessage({
                        id: 'subcontractor_list.revoke_subcontractors.success_edit_message',
                        defaultMessage: 'Sous-traitant supprimé avec succès',
                        description: 'SubContractor revoked',
                    }),
                });
                loadSubContractors();
            }
        }
    }, [previous, organizationUpdateState, loadSubContractors, formatMessage]);

    useEffect(() => {
        if (availableLangs.includes(locale)) {
            if (locale === SupportedLanguage.en) {
                (async () => {
                    const localeData = (await import('../../i18n/countries/en.json')).default;
                    setLocalization(localeData);
                })();
            } else {
                (async () => {
                    const localeData = (await intlData[locale as SupportedLanguage]?.())?.default;
                    setLocalization(localeData);
                })();
            }
        }
    }, [locale]);

    useEffect(() => {
        const afterRemove = previous?.subContractorRemoveState.loading && !subContractorRemoveState.loading;

        if (afterRemove) {
            if (subContractorRemoveState.success) {
                loadSubContractors();
            } else {
                message.error(formatMessage(genericMessages.defaultError));
            }
        }
    }, [formatMessage, previous, subContractorRemoveState, loadSubContractors]);

    useEffect(() => {
        if (downloadSubcontractorAttachmentState.success && downloadedFileName) {
            downloadBlobFile(downloadedFileName, downloadSubcontractorAttachmentState.data);
            downloadAttachmentReset();
            setDownloadedFileName(undefined);
        }
    }, [downloadSubcontractorAttachmentState, downloadedFileName, downloadAttachmentReset, setDownloadedFileName]);
    return (
        <>
            <div className="py-24 px-16 flex items-center justify-between bg-lightish-grey mt-2 mb-8 rounded-b rounded-t space-x-3.5">
                <Typography.Text>
                    {formatMessage({
                        id: 'subcontractor_list.revoke_subcontractors',
                        defaultMessage: `Je n'ai plus de sous-traitant`,
                        description: 'label',
                    })}
                </Typography.Text>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={subContractorRevoked}
                    checked={subContractorRevoked}
                    onChange={onChangeShowConfirmationModal}
                />
            </div>

            {subContractorListState.data?.items.map((subContractor, index) => (
                <div style={index > 0 ? { marginTop: '2rem' } : undefined} key={subContractor.id}>
                    <div className="flex flex-between" style={{ marginBottom: '0.25rem' }}>
                        <Typography.Text
                            style={{
                                textTransform: 'uppercase',
                                fontSize: '1.25rem',
                                letterSpacing: '1.25px',
                                lineHeight: '1rem',
                            }}
                        >
                            {subContractor.companyName}
                            {subContractor.validationStatus &&
                                ![ValidationStatus.autoValidated, ValidationStatus.rejected].includes(
                                    subContractor.validationStatus
                                ) && (
                                    <>
                                        <Divider type="vertical" style={{ height: '20px' }} />
                                        <Tooltip
                                            title={
                                                subContractor.validationStatus === ValidationStatus.pending
                                                    ? formatMessage({
                                                          id: 'subcontractor_list.tooltip.status.pending',
                                                          defaultMessage: 'En attente de validation',
                                                          description: 'tooltip',
                                                      })
                                                    : formatMessage({
                                                          id: 'subcontractor_list.tooltip.status.validated',
                                                          defaultMessage: 'Sous-traitant validé',
                                                          description: 'tooltip',
                                                      })
                                            }
                                            placement={'right'}
                                        >
                                            {subContractor.validationStatus === ValidationStatus.pending ? (
                                                <IconHourglass className="note-information" />
                                            ) : (
                                                <CheckCircleOutlined className="tag-status--approved" />
                                            )}
                                        </Tooltip>
                                    </>
                                )}
                        </Typography.Text>

                        <Space size="middle">
                            <ButtonIcon
                                icon={<EditOutlinedWithToolTip />}
                                onClick={onClickEdit.bind(null, subContractor)}
                            />
                            <DeletePopConfirm
                                placement="topRight"
                                onConfirm={onClickDelete.bind(null, subContractor.id)}
                            >
                                <ButtonIcon icon={<DeleteOutlined />} />
                            </DeletePopConfirm>
                        </Space>
                    </div>
                    <Descriptions column={1} style={{ marginBottom: '1.5rem' }}>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.address',
                                defaultMessage: 'Adresse de fabrication',
                                description: 'label',
                            })}
                        >{`${subContractor.address?.street ?? ''}, ${subContractor.address?.zipCode ?? ''} ${
                            subContractor.address?.city ?? ''
                        }`}</Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.country',
                                defaultMessage: 'Pays',
                                description: 'label',
                            })}
                        >
                            {localization?.[subContractor.address?.country ?? SupportedLanguage.en] ?? ''}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label={formatMessage({
                                id: 'subcontractor_list.description.item.label.activity',
                                defaultMessage: 'Activité(s)',
                                description: 'label',
                            })}
                        >
                            {subContractor.activities?.map((activity, index) => (
                                <Fragment key={index}>
                                    <Translate text={activity?.fields?.name as Translation} />
                                    {subContractor.activities && index < subContractor.activities.length - 1 && ', '}
                                </Fragment>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                    {subContractor.contacts?.map((contact, index) => (
                        <Descriptions
                            title={
                                index === 0
                                    ? formatMessage({
                                          id: 'subcontractor_list.description.item.label.contact',
                                          defaultMessage: 'Contact(s) au sein du sous-traitant',
                                          description: 'label',
                                      })
                                    : undefined
                            }
                            column={1}
                            key={index}
                            style={index > 0 ? { marginTop: '1rem' } : undefined}
                        >
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.name',
                                    defaultMessage: 'Prénom & Nom',
                                    description: 'label',
                                })}
                            >
                                {getFullName(contact)}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.email',
                                    defaultMessage: 'Adresse e-mail',
                                    description: 'label',
                                })}
                            >
                                {contact.email}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.phone',
                                    defaultMessage: 'Téléphone',
                                    description: 'label',
                                })}
                            >
                                {contact.phone}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.job',
                                    defaultMessage: 'Poste / Titre',
                                    description: 'label',
                                })}
                            >
                                {contact.job}
                            </Descriptions.Item>
                        </Descriptions>
                    ))}
                    {subContractor.attachment && (
                        <Descriptions className="mt-6">
                            <Descriptions.Item
                                label={formatMessage({
                                    id: 'subcontractor_list.description.item.label.attachment',
                                    defaultMessage: 'Pièce jointe',
                                    description: 'label',
                                })}
                            >
                                <GenericFileList
                                    file={subContractor.attachment}
                                    downloadOnClick
                                    noNeedFileDownload
                                    noNeedFileRemove
                                    onFileDownload={() => {
                                        setDownloadedFileName(subContractor.attachment?.originalName);
                                        downloadAttachment({
                                            id: subContractor.id,
                                            entity: EntityToDownload.attachment,
                                        });
                                    }}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                    <Divider style={{ minWidth: 32, width: 32 }} />
                </div>
            ))}

            {!subContractorRevoked && (
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={onClickAdd} size="small" shape="round">
                        <FormattedMessage
                            id="subcontractor_list.button.add"
                            defaultMessage="Ajouter un sous-traitant"
                            description="call to action"
                        />
                    </Button>
                </div>
            )}

            <SubContractorFormDrawer
                visible={isFormDrawerVisible}
                onClose={onCloseFormDrawer}
                subContractorId={selectedSubContractor}
            />

            <ConfirmationModal
                id={subcontractorConfirmationModalId}
                width={420}
                closable={false}
                title={formatMessage(genericMessages.confirmation)}
                cancelText={formatMessage(genericMessages.cancel)}
                confirmText={formatMessage(genericMessages.validate)}
                text={formatMessage({
                    id: 'subcontractor_list.revoke_subcontractors.confirmation.message',
                    defaultMessage: 'Attention en cliquant sur “Valider” les données saisies seront supprimées.',
                })}
                onClose={handleModalClose}
                onConfirmation={confirmSubcontractorRevoke}
            />
        </>
    );
};

export default SubContractorList;
