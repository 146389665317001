import { Badge } from 'antd';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, Route } from 'react-router-dom';

import { getUser } from '../../store/actions/auth';
import { getOrdersListState, list as ordersList } from '../../store/actions/orders';
import {
    getProviderCertificationsListCountState,
    listCount as certificationListCount,
} from '../../store/actions/providerCertifications';

import BasicList from '../../components/BasicList';
import { useActions } from '../../hooks';
import { getRawRoute, getRoute, RoutePathName } from '../../routes';
import MaterialCertificationsList from './MaterialCertificationsList';
import OrdersList from './orders/OrdersList';
import ProviderCertificationsList from './ProviderCertificationsList';

const Documents: FC = () => {
    const [loadOrders, loadSupplierCertificates] = useActions([ordersList.trigger, certificationListCount.trigger]);
    const purchaseOrderListCount = useSelector(getOrdersListState);
    const purchaseCertificationListCount = useSelector(getProviderCertificationsListCountState);
    const user = useSelector(getUser);

    useEffect(() => {
        loadOrders({
            sort: 'createdAt',
            sortOrder: 'desc',
            provider: user?.organization?.id,
        });
    }, [loadOrders, user?.organization]);
    useEffect(() => {
        loadSupplierCertificates({
            sort: 'createdAt',
            sortOrder: 'desc',
            provider: user?.organization?.id,
        });
    }, [loadSupplierCertificates, user?.organization]);
    const showRefusedCount =
        purchaseCertificationListCount?.data?.countRefused && purchaseCertificationListCount?.data?.countRefused > 0;
    const countPendingOrders = purchaseOrderListCount?.data?.providerPendingDocumentCount ?? 0;

    return (
        <>
            <nav className="mb-8">
                <BasicList inline>
                    <li>
                        <NavLink to={getRoute(RoutePathName.purchaseOrderList)} exact>
                            <FormattedMessage
                                id="document.nav_link.order"
                                defaultMessage="Ordres d'achat / commessa"
                                description="documents page menu item"
                            />
                            {countPendingOrders > 0 && (
                                <Badge count={countPendingOrders} style={{ backgroundColor: '#52c41a' }} />
                            )}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={getRoute(RoutePathName.providerCertificationsList)} exact>
                            <FormattedMessage
                                id="document.nav_link.provider_certifications"
                                defaultMessage="Attestations fournisseurs"
                                description="documents page menu item"
                            />
                            {!!showRefusedCount && (
                                <Badge
                                    count={purchaseCertificationListCount?.data?.countRefused}
                                    style={{ backgroundColor: '#EA4C41' }}
                                />
                            )}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={getRoute(RoutePathName.materialCertificationsList)} exact>
                            <FormattedMessage
                                id="document.nav_link.material_certifications"
                                defaultMessage="Certifications matières"
                                description="documents page menu item"
                            />
                        </NavLink>
                    </li>
                </BasicList>
            </nav>
            <Route path={getRawRoute(RoutePathName.purchaseOrderList)} exact>
                <OrdersList />
            </Route>
            <Route path={getRawRoute(RoutePathName.providerCertificationsList)} exact>
                <ProviderCertificationsList />
            </Route>
            <Route path={getRawRoute(RoutePathName.materialCertificationsList)} exact>
                <MaterialCertificationsList />
            </Route>
        </>
    );
};

export default Documents;
