import { combineReducers } from 'redux';

import { AuthState, authReducer, preHooksReducer, PreHookState } from '../actions/auth';
import { CookiesState, cookiesReducer } from '../actions/cookies';
import { LogsState, logsReducer } from '../actions/logs';
import { UsersState, usersReducer } from '../actions/users';
import { OrganizationsState, organizationsReducer } from '../actions/organizations';
import { BrandsState, brandsReducer } from '../actions/brands';
import { MaterialCertificationsState, materialCertificationsReducer } from '../actions/materialCertifications';
import { CertificationsState, certificationsReducer } from '../actions/certifications';
import { CollectionsState, collectionsReducer } from '../actions/collections';
import { OrdersState, ordersReducer } from '../actions/orders';
import { ProviderCertificationsState, providerCertificationsReducer } from '../actions/providerCertifications';
import { RevenuesState, revenuesReducer } from '../actions/revenue';
import { MajInfoProductsState, majInfoProductReducer } from '../actions/majInfoProduct';
import { FactoriesState, factoriesReducer } from '../actions/factories';
import { AuditsState, auditsReducer } from '../actions/audits';
import { TypeOfAuditsState, typeOfAuditsReducer } from '../actions/typeOfAudits';
import { SubContractorsState, subContractorsReducer } from '../actions/SubContractors';
import { ConformityDocumentsState, conformityDocumentsReducer } from '../actions/conformityDocuments';
import { MaterialsState, materialsReducer } from '../actions/materials';
import { SubmitState, submitReducer } from '../actions/submit';
import { ApiInfosState, apiInfosReducer } from '../actions/apiInfos';
import { remoteFileReducer, RemoteFileState } from '../actions/remoteFiles';

export interface MainReducerState {
    readonly auth: AuthState;
    readonly apiInfos: ApiInfosState;
    readonly cookies: CookiesState;
    readonly logs: LogsState;
    readonly users: UsersState;
    readonly organizations: OrganizationsState;
    readonly materialCertifications: MaterialCertificationsState;
    readonly brands: BrandsState;
    readonly certifications: CertificationsState;
    readonly collections: CollectionsState;
    readonly orders: OrdersState;
    readonly providerCertifications: ProviderCertificationsState;
    readonly revenues: RevenuesState;
    readonly majInfoProducts: MajInfoProductsState;
    readonly factories: FactoriesState;
    readonly audits: AuditsState;
    readonly typeOfAudits: TypeOfAuditsState;
    readonly subContractors: SubContractorsState;
    readonly conformityDocuments: ConformityDocumentsState;
    readonly materials: MaterialsState;
    readonly submit: SubmitState;
    readonly preHook: PreHookState;
    readonly remoteFile: RemoteFileState;
}

const mainReducer = combineReducers<MainReducerState>({
    auth: authReducer,
    apiInfos: apiInfosReducer,
    cookies: cookiesReducer,
    logs: logsReducer,
    users: usersReducer,
    organizations: organizationsReducer,
    materialCertifications: materialCertificationsReducer,
    brands: brandsReducer,
    certifications: certificationsReducer,
    collections: collectionsReducer,
    orders: ordersReducer,
    providerCertifications: providerCertificationsReducer,
    revenues: revenuesReducer,
    majInfoProducts: majInfoProductReducer,
    factories: factoriesReducer,
    audits: auditsReducer,
    typeOfAudits: typeOfAuditsReducer,
    subContractors: subContractorsReducer,
    conformityDocuments: conformityDocumentsReducer,
    materials: materialsReducer,
    submit: submitReducer,
    preHook: preHooksReducer,
    remoteFile: remoteFileReducer,
});

export default mainReducer;

export interface RequestState<T = any> {
    readonly data?: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
    readonly total?: number;
}
